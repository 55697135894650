import logo from "./logo.svg";
import "./App.css";
import MessengerCustomerChat from "react-messenger-customer-chat";
import { useEffect } from "react";
import Amplitude from "amplitudejs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faWhatsapp,
  faViber,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used

import {} from "@fortawesome/fontawesome-svg-core";
function App() {
  useEffect(() => {
    Amplitude.init({
      songs: [
        {
          name: "Risin' High (feat Raashan Ahmad)",
          artist: "Ancient Astronauts",
          album: "We Are to Answer",
          url: "assets/audio/0000029.mp3",
          cover_art_url: "assets/img/album-art/we-are-to-answer.jpg",
        },
        {
          name: "The Gun",
          artist: "Lorn",
          album: "Ask The Dust",
          url: "assets/audio/0000104.mp3",
          cover_art_url: "assets/img/album-art/ask-the-dust.jpg",
        },
        {
          name: "Anvil",
          artist: "Lorn",
          album: "Anvil",
          url: "assets/audio/0000154.mp3",
          cover_art_url: "assets/img/album-art/anvil.jpg",
        },
        {
          name: "I Came Running",
          artist: "Ancient Astronauts",
          album: "We Are to Answer",
          url: "assets/audio/0000215.mp3",
          cover_art_url: "assets/img/album-art/we-are-to-answer.jpg",
        },
      ],
    });
  }, []);
  return (
    <div className="App">
      <header>
        <div class="nav">
          <div class="title">
            <h2>Records</h2>
          </div>
          <div class="toggle">
            <div class="bar"></div>
          </div>

          <nav class="hidden">
            <div class="showcase">
              <div class="showcase-container">
                <ul class="showcase-menu">
                  <li
                    class="overlay"
                    style={{
                      backgroundImage:
                        'url("https://images.pexels.com/photos/797579/pexels-photo-797579.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")',
                    }}
                  >
                    Hello !
                  </li>
                  <li
                    class="overlay"
                    style={{
                      backgroundImage:
                        'url("https://images.pexels.com/photos/210766/pexels-photo-210766.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")',
                    }}
                  >
                    We are Noble Freaks.
                  </li>
                  <li
                    class="overlay"
                    style={{
                      backgroundImage:
                        'url("https://images.pexels.com/photos/352505/pexels-photo-352505.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")',
                    }}
                  >
                    Remember Us
                  </li>
                  <li
                    class="overlay"
                    style={{
                      backgroundImage:
                        'url("https://images.unsplash.com/photo-1457052002176-2d16a4f4a3ff?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80")',
                    }}
                  >
                    Join our class
                  </li>
                  <li
                    class="overlay"
                    style={{
                      backgroundImage:
                        'url("https://images.pexels.com/photos/33779/hand-microphone-mic-hold.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940")',
                    }}
                  >
                    Projects we have worked.
                  </li>
                  <li
                    class="overlay"
                    style={{
                      backgroundImage:
                        'url("https://images.unsplash.com/photo-1523405976-1345529cc3f4?ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80")',
                    }}
                  >
                    What our clients think.
                  </li>
                  <li
                    class="overlay"
                    style={{
                      backgroundImage:
                        'url("https://images.unsplash.com/uploads/1412282232015a06e258a/4bdd2a58?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80")',
                    }}
                  >
                    Contact us for more details.
                  </li>
                </ul>
              </div>
            </div>

            <div class="menu">
              <ul class="main-menu">
                <li class="active">
                  <a href="#!">Home</a>
                </li>
                <li>
                  <a href="#!">About Us</a>
                </li>
                <li>
                  <a href="#!">Services</a>
                </li>
                <li>
                  <a href="#!">Class</a>
                </li>
                <li>
                  <a href="#!">Portfolio</a>
                </li>
                <li>
                  <a href="#!">Testimonials</a>
                </li>
                <li>
                  <a href="#!">Contact Us</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      <div id="main-section">
        <section
          class="section  section-home text-center"
          style={{
            backgroundImage: 'url("assets/img/background/mic-dark.jpg")',
            backgroundPosition: "auto 100%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            color: "white",
          }}
        >
          <div className="bottom-container">
            <h3>Contact us</h3>
            <div className="contact-icons">
              <a href="tel:+9779841255921">
                <FontAwesomeIcon icon={solid("phone-alt")} color="blue" />
              </a>

              <a
                href="https://wa.me/9841255921"
                class="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={brands("whatsapp")} color="green" />
              </a>
              <a href="viber://chat?number=+9779803666989">
                <FontAwesomeIcon icon={brands("viber")} color="rebeccapurple" />
              </a>
            </div>
          </div>
          <MessengerCustomerChat
            pageId="102104562488700"
            appId="878260336223931"
            // htmlRef="<REF_STRING>"
          />
        </section>
        <section
          class="section text-center"
          style={{ background: "#1c2433", color: "white" }}
        >
          <div class="row" id="blue-playlist-container">
            <div class="col-md-12" id="amplitude-player">
              <div class="row">
                <div class="col-md-6" id="amplitude-left">
                  <img data-amplitude-song-info="cover_art_url" />
                  <div id="player-left-bottom">
                    <div id="time-container">
                      <span class="current-time">
                        <span class="amplitude-current-minutes"></span>:
                        <span class="amplitude-current-seconds"></span>
                      </span>
                      <div id="progress-container">
                        <input type="range" class="amplitude-song-slider" />
                        <progress
                          id="song-played-progress"
                          class="amplitude-song-played-progress"
                        ></progress>
                        <progress
                          id="song-buffered-progress"
                          class="amplitude-buffered-progress"
                          value="0"
                        ></progress>
                      </div>
                      <span class="duration">
                        <span class="amplitude-duration-minutes"></span>:
                        <span class="amplitude-duration-seconds"></span>
                      </span>
                    </div>

                    <div id="control-container">
                      <div id="repeat-container">
                        <div class="amplitude-repeat" id="repeat"></div>
                        <div
                          class="amplitude-shuffle amplitude-shuffle-off"
                          id="shuffle"
                        ></div>
                      </div>

                      <div id="central-control-container">
                        <div id="central-controls">
                          <div class="amplitude-prev" id="previous"></div>
                          <div
                            class="amplitude-play-pause"
                            id="play-pause"
                          ></div>
                          <div class="amplitude-next" id="next"></div>
                        </div>
                      </div>

                      <div id="volume-container">
                        <div class="volume-controls">
                          <div class="amplitude-mute amplitude-not-muted"></div>
                          <input type="range" class="amplitude-volume-slider" />
                          <div class="ms-range-fix"></div>
                        </div>
                        <div
                          class="amplitude-shuffle amplitude-shuffle-off"
                          id="shuffle-right"
                        ></div>
                      </div>
                    </div>

                    <div id="meta-container">
                      <span
                        data-amplitude-song-info="name"
                        class="song-name"
                      ></span>

                      <div class="song-artist-album">
                        <span data-amplitude-song-info="artist"></span>
                        <span data-amplitude-song-info="album"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6" id="amplitude-right">
                  <div class="song-holder">
                    <div
                      class="song amplitude-song-container amplitude-play-pause"
                      data-amplitude-song-index="0"
                    >
                      <div class="song-now-playing-icon-container">
                        <div class="play-button-container"></div>
                        <img
                          class="now-playing"
                          src="assets/icon/now-playing.svg"
                        />
                      </div>
                      <div class="song-meta-data">
                        <span class="song-title">
                          Risin' High (feat Raashan Ahmad)
                        </span>
                        <span class="song-artist">Ancient Astronauts</span>
                      </div>
                      <a
                        href="https://switchstancerecordings.bandcamp.com/track/risin-high-feat-raashan-ahmad"
                        class="bandcamp-link"
                        target="_blank"
                      >
                        <img
                          class="bandcamp-grey"
                          src="assets/icon/bandcamp-grey.svg"
                        />
                        <img
                          class="bandcamp-white"
                          src="assets/icon/bandcamp-white.svg"
                        />
                      </a>
                      <span class="song-duration">3:30</span>
                    </div>
                    <div
                      class="song amplitude-song-container amplitude-play-pause"
                      data-amplitude-song-index="1"
                    >
                      <div class="song-now-playing-icon-container">
                        <div class="play-button-container"></div>
                        <img
                          class="now-playing"
                          src="assets/icon/now-playing.svg"
                        />
                      </div>
                      <div class="song-meta-data">
                        <span class="song-title">The Gun</span>
                        <span class="song-artist">Lorn</span>
                      </div>
                      <a
                        href="https://lorn.bandcamp.com/"
                        class="bandcamp-link"
                        target="_blank"
                      >
                        <img
                          class="bandcamp-grey"
                          src="assets/icon/bandcamp-grey.svg"
                        />
                        <img
                          class="bandcamp-white"
                          src="assets/icon/bandcamp-white.svg"
                        />
                      </a>
                      <span class="song-duration">3:16</span>
                    </div>
                    <div
                      class="song amplitude-song-container amplitude-play-pause"
                      data-amplitude-song-index="2"
                    >
                      <div class="song-now-playing-icon-container">
                        <div class="play-button-container"></div>
                        <img
                          class="now-playing"
                          src="assets/icon/now-playing.svg"
                        />
                      </div>
                      <div class="song-meta-data">
                        <span class="song-title">Anvil</span>
                        <span class="song-artist">Lorn</span>
                      </div>
                      <a
                        href="https://lorn.bandcamp.com/"
                        class="bandcamp-link"
                        target="_blank"
                      >
                        <img
                          class="bandcamp-grey"
                          src="assets/icon/bandcamp-grey.svg"
                        />
                        <img
                          class="bandcamp-white"
                          src="assets/icon/bandcamp-white.svg"
                        />
                      </a>
                      <span class="song-duration">3:32</span>
                    </div>
                    <div
                      class="song amplitude-song-container amplitude-play-pause"
                      data-amplitude-song-index="3"
                    >
                      <div class="song-now-playing-icon-container">
                        <div class="play-button-container"></div>
                        <img
                          class="now-playing"
                          src="assets/icon/now-playing.svg"
                        />
                      </div>
                      <div class="song-meta-data">
                        <span class="song-title">I Came Running</span>
                        <span class="song-artist">Ancient Astronauts</span>
                      </div>
                      <a
                        href="https://switchstancerecordings.bandcamp.com/track/i-came-running"
                        class="bandcamp-link"
                        target="_blank"
                      >
                        <img
                          class="bandcamp-grey"
                          src="assets/icon/bandcamp-grey.svg"
                        />
                        <img
                          class="bandcamp-white"
                          src="assets/icon/bandcamp-white.svg"
                        />
                      </a>
                      <span class="song-duration">3:30</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          class="section text-center"
          style={{ background: "rgba(0,0,0,1)", color: "white" }}
        ></section>
      </div>
    </div>
  );
}

export default App;
